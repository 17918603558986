
import {computed, defineComponent, PropType, provide, reactive, ref} from 'vue';
import {ElForm} from 'element-plus';

export default defineComponent({
  name: 'Form',
  props: {
    model: {
      type: Object as PropType<FormModel>,
      default: () => {
        return {};
      }
    },
    inline: {
      type: Boolean,
      default: true,
    },
    labelWidth: {
      type: String,
      default: '150px',
    },
    size: {
      type: String,
      default: 'mini',
    },
    grid: {
      type: Number,
      default: 4,
    },
    rules: {
      type: Object as PropType<FormRules>,
    },
  },
  emits: [
    'validate',
  ],
  setup(props: FormProps, {emit}) {
    const form = computed<FormContext>(() => {
      const {labelWidth, size, grid} = props;
      return {labelWidth, size, grid};
    });

    provide('form-context', reactive<FormContext>(form.value));

    const formRef = ref<typeof ElForm>();

    const validate = async () => {
      return await formRef.value?.validate();
    };

    const resetFields = () => {
      return formRef.value?.resetFields();
    };

    const clearValidate = () => {
      return formRef.value?.clearValidate();
    };

    return {
      formRef,
      validate,
      resetFields,
      clearValidate,
    };
  },
});
